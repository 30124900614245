import Link from 'next/link';
import Layout from '../components/layout';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useGetReservationQuery } from '../services/bookingApi';

export default function Index() {
  const { query, replace } = useRouter();
  const bookingId = query.bookingId;

  const { data: reservation } = useGetReservationQuery(bookingId as string, {
    skip: !bookingId?.length,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!reservation?.objectId) return;

    replace(`/confirmation?bookingId=${reservation?.objectId}`);
  }, [reservation?.objectId, reservation?.venueId]);

  return (
    <Layout>
      {/* Pinchos Booking Client.{' '}
      <Link href="/venue/se-hq-it/new-reservation" className="text-blue-200">
        Take me to the booking
      </Link> */}
    </Layout>
  );
}
